import Routes from "src/routes";

interface Question {
  question: string;
  answer: string;
}

export interface QuestionGroup {
  title: string;
  slug: string;
  questions: Question[];
}

export const primeGamingFaq: Question[] = [
  {
    question:
      "What kind of drops will be available through Prime Gaming, and when will they be available?",
    answer:
      "6 drops will be available from June 8, 2023 to January 4, 2024, including but not limited to: Blankos, Accessories, and in-game currency. All Blankos and Accessories are NFTs that can be equipped and played with in game.",
  },
  {
    question:
      "Do I need a Prime membership to be eligible for Prime Gaming offers?",
    answer:
      "Yes. If you are not already an Amazon Prime member, sign up <a href='https://www.amazon.com/gp/prime/pipeline/signup'>here</a> for a free 30-day Prime trial. <br>If you are an Amazon Prime member, ensure that you are subscribed to Prime Gaming by <a href='https://www.amazongames.com/en-us/support/prime-gaming/articles/subscribe-to-prime-gaming'>following these steps</a>.",
  },
  {
    question:
      "What happens to my content if I no longer have a Prime membership or my free trial expires?",
    answer:
      "Once you claim your content, all content that is delivered to your game is yours to keep, subject to any <strong>Blankos Block Party</strong> rules or policies that apply to the content.",
  },
  {
    question: "How do I access my Blankos and accessories in game?",
    answer:
      "After claiming the offer and linking your Prime and Blankos accounts, launch Blankos Block Party. Check your Shelf for your new Blanko. Accessories are available with any Blanko that has been unboxed in the accessories tab.",
  },
];

const summary: Question[] = [
  {
    question: "What is Blankos Block Party?",
    answer:
      "Blankos Block Party is a vibrant free-to-play open-world multiplayer game styled like a giant block party, with a focus on custom art and design." +
      "<br /><br />" +
      "You control and collect Blankos, fun and mischievous digital vinyl toys brought to life!" +
      "<br /><br />" +
      "Each Blanko is a digital asset called an NFT that lives on a blockchain. When you own a Blanko, you own them - not even the creator can take them away from you.",
  },
  {
    question: "What is the Mythical Marketplace?",
    answer:
      "Mythical Marketplace is an online market where players can both browse in-game NFTs that are owned by other players to purchase an NFT or sell their own NFT on the market!" +
      "<br /><br />" +
      "If you would like to learn more about Mythical Marketplace, you can check out our FAQs <a href='https://support.blankos.com/hc/en-us/categories/10547810979867-Shop-Blankos-Marketplace' target='_blank'>here</a> for more in depth information.",
  },
  {
    question: "What are the system requirements?",
    answer:
      "PC:" +
      "<br />" +
      "PROCESSOR: Intel Core i7-4770AMD FX 8310" +
      "<br />" +
      "MEMORY: 8 GB RAM" +
      "<br />" +
      "VIDEO: Nvidia GTX 660, AMD Radeon HD 7870, DX11 GPU" +
      "<br />" +
      "RESOLUTION: 1920x1080, Graphics Quality - High 60 FPS" +
      "<br />" +
      "STORAGE: 4GB of free space" +
      "<br /><br />" +
      "Mac:" +
      "<br />" +
      "PROCESSOR: 2.3 GHz Intel Core i5 or equivalent" +
      "<br />" +
      "MEMORY: 8 GB RAM" +
      "<br />" +
      "VIDEO: Intel Iris Plus Graphics 640 or equivalent" +
      "<br />" +
      "RESOLUTION: 1344x756" +
      "<br />" +
      "STORAGE: 4GB of free space",
  },
  {
    question: "Need Help? Here is our Support FAQ!",
    answer:
      "If you have questions or need assistance with any kind of issue, please looking through our FAQ or contact us here: <a href='https://support.blankos.com/' target='_blank'>Mythical FAQ and Support</a>",
  },
];

const full: QuestionGroup[] = [
  {
    title: "Release Info",
    slug: "release-info",
    questions: [
      {
        question: "What is Blankos Block Party?",
        answer:
          "Blankos Block Party is a free-to-play MMO set in a vibrant online world styled like a giant block party, with a focus on custom art and design, world-building and exploration, and collecting unique Blankos.",
      },
      {
        question: "What platforms will Blankos Block Party be available on?",
        answer:
          "Currently, Blankos Block Party is available for all regions on macOS and PC. Stay tuned for announcements about other platforms!",
      },
      {
        question: "Is Blankos Block Party Free to Play?",
        answer:
          "Blankos Block Party is free to play, with the option for purchasable in-game cosmetics, accessories, and limited edition Blankos.",
      },
      {
        question: "What regions and language will Blankos Block Party support?",
        answer:
          "Our initial access for Beta testing will be focused on English-speaking countries including North America, UK, New Zealand, Australia and Canada. The game will later be available in most countries and in several different languages - complete list to be confirmed closer to launch.",
      },
      {
        question: "What is this blockchain thing people talk about?",
        answer:
          "Blankos utilizes blockchain technology to give players proof of verified ownership and transaction history - simply put, this means more control for players over the things they buy!",
      },
      {
        question:
          "What about my SXSW cards or Blanko Card I got for signing up at an event?",
        answer:
          "If you were awarded a free Blanko Card through SXSW 2019 or at another event for participating, you will no longer be able to redeem them after September 28th at 10:00 AM PDT / 17:00 UTC. Please note some Cards had an earlier expiration date. If your card has already been redeemed, or you attempt to redeem after the cutoff date, our support team will be unable to assist you with your issue.",
      },
    ],
  },
  {
    title: "Game Play and Parties",
    slug: "gameplay",
    questions: [
      {
        question: "What type of parties can I build?",
        answer:
          "Currently Blankos features shooting, racing, and collection game styles (or combine them to make something brand new!). We will continue to add new building items, toys, and accessories ongoing to let you build crazy new parties.",
      },
      {
        question: "Do I have to know how to code to build a Party?",
        answer:
          "Nope, not a single line of code is needed! You simply build a Party, design and build out your parties, drop inventory and toys into it, and then configure what the rules of the game play are. Everything can be done with a game controller or mouse/keyboard and the game takes care of the rest! You can also just create a Party for players to hang out if you want. It’s all up to you.",
      },
      {
        question: "Are there rewards for building cool or popular Parties?",
        answer:
          "Yes! More info on this later but there will be rewards and unlocks for players that build awesome and popular Parties.",
      },
      {
        question: "Is there a single-player playing option?",
        answer:
          "Parties can be created as multi-player or single-player. Blankos love Parties with other Blankos, but single-players who want to fly solo can also explore, collect, and complete challenges in The Junction.",
      },
    ],
  },
  {
    title: "Blankos",
    slug: "blankos",
    questions: [
      {
        question: "What is a Blanko?",
        answer:
          "Blankos are fun and mischievous collectible digital vinyl toys brought to life. Inhabiting The Junction, their own offbeat world, they love good vibes and good times!",
      },
      {
        question: "Where can I see all the Blankos available?",
        answer:
          "The current offering of Blankos is available in The Shop in game or here on the website. Some items like emotes and certain accessories are only available in the game, so download Early Access for free to discover them!",
      },
      {
        question: "Do Blankos have accessories or just skins?",
        answer:
          "Blankos love accessories. Not only can you get different Blankos for your squad but you can trick them out with cool gear.",
      },
      {
        question: "Do Blankos have abilities or powers?",
        answer:
          "Yes, you can choose abilities and level up your Blankos as you play and build. Select from Speedster, Trickster, or Bruiser skill trees when you unbox a Blanko.",
      },
      {
        question: "Can I create my own Blankos?",
        answer:
          "New Blankos are currently created through artist partnerships. We already have some ultra- talented vinyl toy artists like Jon-Paul Kaiser, Tara McPherson, Junko Mizuno, Pete Fowler, Kronk, and James Groman making Blankos for you to own. Players will have some awesome opportunities to customize Blankos with accessories and attachments that we will share in the future.",
      },
    ],
  },
  {
    title: "Amazon Prime Gaming",
    slug: "prime-gaming",
    questions: [
      ...primeGamingFaq,
      {
        question:
          "How do I use my XP Chips, Gumballs, Moola, and Blankos Bucks?",
        answer: `XP Chips can be found in the GradeUP section in Quick Access and are used to level up any of your unboxed Blankos. Click on an unboxed Blanko to boost! To enter the Quick Access menu, just click on the Blanko on your desk.
        <p>Gumballs are used to give your Blankos new colors! To use Gumballs access the "Vendor Blender" in the Junction town center or through the MashUP button in Quick Access.</p>
        Moola and Blanko Bucks are in-game currency. These are immediately available upon claim and can be used on premium items in the in-game shop, or to GradeUP and MashUP your Blankos.`,
      },
      {
        question: "Where can I get Blankos Block Party?",
        answer: `Blankos Block Party is available for macOS and PC. Download it for your preferred platform <a href="${Routes.Download}">here.</a> or the <a href="https://store.epicgames.com/">Epic Games Store</a>`,
      },
      {
        question: "Will I be able to access my content on multiple platforms?",
        answer:
          "Yes. Content will be available across all platforms you log in to with your linked Blankos Block Party account. Blankos Block Party is currently on macOS and PC.",
      },
      {
        question: "I claimed a drop but don't see my content in game.",
        answer:
          "If you’ve claimed the offer but don’t see it in game, make sure that the Amazon Prime account you used to claim the offer is linked to the same account you use to play Blankos Block Party.",
      },
      {
        question: "Is this content available in all regions?",
        answer:
          "Yes, Blankos Block Party is available for all regions on macOS and PC.",
      },
      {
        question:
          "Blankos Block Party NFTs are part of the Mythical blockchain which is private. Are there plans to open that up so the NFTs can be moved to other chains?",
        answer:
          'Yes! We are already working on this. We have plans to bridge it to Ethereum in Q1 2022. Please follow Blankos Block Party social media for any related announcements. If you\'d like to know more about the Mythical block chain, please visit <a href="https://mythicalgames.com/platform">https://mythicalgames.com/platform</a>',
      },
      {
        question:
          "Items like Blankos I receive are limited edition NFTs. How do I find the serial number of my items?",
        answer: `There are two ways to do this! The first way is to <a href="${Routes.Download}">download Blankos Block Party</a>. Once the game is installed, you can load into the game, visit the "Shelf," and click on your item to see its serial number. The second way is to visit the <a href="https://blankos.mythical.market/">Mythical Marketplace</a> and log in or sign-up using the same login info for Blankos Block Party. All your NFTs and their serial numbers can be found in the Collection tab! You can also buy and sell your NFTs here.`,
      },
      {
        question: "Do any of these NFT content offers expire?",
        answer:
          "Yes. If you have claimed any of the drops on Prime Gaming but not logged into Blankos Block Party, we will keep the in-game content redemption active until a certain period. You must download Blankos Block Party and log into the game to complete the full redemption process ensuring you receive your content. For more information, please keep an eye out on our blogs.",
      },
      {
        question: "What if I have an issue that isn't addressed above?",
        answer:
          'If you are having trouble with your Amazon Prime membership, please reference <a href="https://www.amazongames.com/en-us/support/prime-gaming">Prime Gaming Support</a> for more information. For issues with your Blankos Block Party account in general, please visit our <a href="https://support.mythicalgames.com/hc/en-us/categories/10628996451355-Account-Login">Account & Login</a> articles for additional information and troubleshooting steps.',
      },
    ],
  },
];

export { summary, full };
