import Link from "next/link";
import React from "react";
import type { Theme } from "@material-ui/core";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Routes from "../../routes";
import BackgroundImage from "../BackgroundImage";

const useStyles = makeStyles((theme: Theme) => ({
  collectSection: {
    minHeight: "95vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  collectContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  sectionContent: {
    width: "30vw",
    marginLeft: "6vw",
    marginBottom: "12vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 0,
      marginTop: "12vh",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
}));

const HomepageCollect = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BackgroundImage
      src={
        smScreen
          ? "/images/banner_collect_mobile.jpg"
          : "/images/banner_collect.jpg"
      }
    >
      <section className={classes.collectSection}>
        <Grid container className={classes.collectContainer}>
          <Grid item md={5}>
            <div style={{ margin: "12vh 6vw" }}>
              <Typography variant="h1" component="h2">
                Collect Iconic Blankos
              </Typography>
              <Typography className={`${classes.joinSubtitle}`}>
                Collect and play with unique Blankos that can be customized and
                leveled up with rare attributes.
              </Typography>
              <Link href={Routes.Download} passHref>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{ marginTop: "32px" }}
                >
                  Play now
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </section>
    </BackgroundImage>
  );
};

export default HomepageCollect;
