import React from "react";
import ReactPlayer from "react-player/youtube";
import type { Theme } from "@material-ui/core";
import { Box, Container, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "500px",
    textAlign: "center",
    padding: theme.spacing(12, 0, 6),
  },
  overviewContainer: {
    position: "relative",
    display: "inline-block",
    width: "auto",
    padding: theme.spacing(8, 0, 10),
  },
}));

const HomepageOverview = () => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.overviewContainer}>
          <Typography variant="h1" component="h2">
            Join the Party
          </Typography>
          <Typography>
            Play, Collect, and Create in the biggest virtual block party on the
            planet!
          </Typography>
        </div>

        <Box maxWidth="1024px" margin="auto">
          <div
            style={{
              position: "relative",
              paddingTop: "56.25%",
            }}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url="https://youtu.be/1IhgggzfSDo"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>
        </Box>
      </Container>
    </section>
  );
};

export default HomepageOverview;
