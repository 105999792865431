import type { StreamPlayerApi } from "@cloudflare/stream-react";
import { Stream } from "@cloudflare/stream-react";
import type { Theme } from "@material-ui/core";
import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { PauseCircleFilled, PlayCircleFilled } from "@material-ui/icons";
import React, { memo, useEffect, useRef, useState } from "react";
import srcImage from "../../../../public//images/homepage-hero-load-frame.png";
import BackgroundImage from "../../BackgroundImage";
import DownloadButton from "../../download/DownloadButton";
import Image from "../../Image";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
    maxHeight: "90vh",
    minHeight: "700px",
    [theme.breakpoints.down("md")]: {
      minHeight: "600px",
    },
    zIndex: 0,
    marginTop: "-100px",
  },
  overlay: {
    zIndex: -1,
    position: "absolute",
    background: "rgba(0, 0, 0, 0.5)",
    height: "100%",
    width: "100%",
    textAlign: "center",
    boxSizing: "border-box",
  },
  overlayButton: { alignSelf: "self-start" },
  playerWrapper: {
    position: "absolute",
    zIndex: -1,
    "& > div": {
      overflow: "hidden",
      width: "100vw",
      height: "100vh",
      "@media (min-aspect-ratio: 16/9)": {
        /* height = 100 * (9 / 16) = 56.25 */
        height: "56.25vw",
      },
      "@media (max-aspect-ratio: 16/9)": {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: "177.78vh",
      },
    },
  },
  heroWrapper: { position: "relative" },
  pauseButton: {
    textTransform: "lowercase",
    textShadow: "1px 1px 0 rgba(0, 0, 0, 0.3)",
    fontSize: "24px",
    transition: "opacity 0.5s",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  gridContainer: {
    padding: "150px 32px 150px",
    [theme.breakpoints.down("md")]: {
      padding: "100px 16px 100px",
      minHeight: "600px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "150px 16px 80px",
    },
    height: "100%",
  },
}));

const HomepageVideo: React.FC<{
  playing: boolean;
  onPause: () => void;
  onPlay: () => void;
  onReady: () => void;
}> = memo(({ playing, onPlay, onPause, onReady }) => {
  const classes = useStyles();

  const ref = useRef<StreamPlayerApi | undefined>();

  const prefersReducedMotion = useMediaQuery(
    "(prefers-reduced-motion: reduce)",
  );
  const mdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  const [showVideo, setShowVideo] = useState<boolean>(false);

  useEffect(() => {
    if (playing) {
      ref?.current?.play();
    } else {
      ref?.current?.pause();
    }
  }, [playing]);

  // Don't bother loading the video if the user
  // 1. is on a mobile device or
  // 2. prefers reduced motion for a11y
  if (mdScreen || prefersReducedMotion) {
    return <></>;
  }

  return (
    <Box
      className={classes.playerWrapper}
      style={{
        opacity: showVideo ? 1 : 0,
        transition: "opacity 0.5s",
      }}
    >
      <Stream
        streamRef={ref}
        onPlay={onPlay}
        onPause={onPause}
        src="7383fcdaa334e9eb5a10330f35befbb2"
        onCanPlay={() => {
          onReady();
          setShowVideo(true);
        }}
        height="1080"
        autoplay
        loop
        muted
        preload
      />
    </Box>
  );
});
HomepageVideo.displayName = "HomepageVideo";

const HomepageHero: React.FC = () => {
  const classes = useStyles();

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [ready, setReady] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(true);

  const onPlay = () => setPlaying(true);
  const onPause = () => setPlaying(false);
  const onReady = () => setReady(true);

  return (
    <BackgroundImage src={srcImage.src} className={classes.root}>
      <HomepageVideo
        playing={playing}
        onPlay={onPlay}
        onPause={onPause}
        onReady={onReady}
      />

      <Box className={classes.overlay} />

      <Grid
        container
        justifyContent="space-between"
        className={classes.gridContainer}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box alignSelf="center">
            <Image
              src="/images/Blankos_Logo_Redesign.png"
              alt="Blankos Block Party Early Access logo"
              width={mdScreen ? 275 : 450}
            />
            <Box pt={mdScreen ? 0 : 2} textAlign="center">
              <Hidden smDown>
                <DownloadButton size="large" />
              </Hidden>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.overlayButton}>
          <Button
            variant="text"
            onClick={() => (playing ? onPause() : onPlay())}
            className={classes.pauseButton}
            style={ready ? {} : { opacity: 0 }}
          >
            {playing ? (
              <>
                <PauseCircleFilled
                  fontSize="large"
                  style={{ marginTop: "4px", marginRight: "8px" }}
                />
                Pause
              </>
            ) : (
              <>
                <PlayCircleFilled
                  fontSize="large"
                  style={{ marginTop: "4px", marginRight: "8px" }}
                />
                Play
              </>
            )}
          </Button>
        </Grid>
      </Grid>
    </BackgroundImage>
  );
};

export default HomepageHero;
