import Link from "next/link";
import React from "react";
import {
  Button,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import BackgroundImage from "../BackgroundImage";
import Image from "../Image";
import Routes from "../../routes";

const useStyles = makeStyles((theme) => ({
  connectSection: {
    minHeight: "95vh",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  sectionContent: {
    width: "30vw",
    marginLeft: "6vw",
    marginBottom: "12vh",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 0,
      marginTop: "12vh",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  emotes: {
    position: "relative",
    marginTop: "-120vh",
    zIndex: 1,
  },
}));

const HomepageConnect = () => {
  // TODO get rid of this wrapper
  return (
    <>
      <Hidden smDown>
        <BackgroundImage src="/images/banner_connect.jpg">
          <Content />
        </BackgroundImage>
      </Hidden>
      <Hidden mdUp>
        <BackgroundImage src="/images/banner_connect_mobile.jpg">
          <Content />
        </BackgroundImage>
      </Hidden>
    </>
  );
};

const Content = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section>
      <div className={classes.connectSection}>
        <div className={classes.sectionContent} style={{ marginRight: "10vw" }}>
          <Typography variant="h1" component="h2">
            Connect with friends
          </Typography>
          <Typography className={classes.joinSubtitle}>
            Whether meeting up in the Junction to show off your Blankos or face
            off in challenges, this is the ultimate place to hang out with
            friends online.
          </Typography>
          <Link href={Routes.Download} passHref>
            <Button
              variant="contained"
              color={smScreen ? "primary" : "secondary"}
              size="large"
              style={{ marginTop: "32px" }}
            >
              Play Now
            </Button>
          </Link>
        </div>
      </div>

      <Hidden only={["xs", "sm", "md"]}>
        {/* TODO these look weird on mobile */}
        <Image
          src="/images/emotes.png"
          aria-hidden={true}
          width={1518}
          height={876}
          className={classes.emotes}
        />
      </Hidden>
    </section>
  );
};

export default HomepageConnect;
