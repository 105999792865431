import { Typography } from "@material-ui/core";
import React from "react";
import HomepageHero from "src/components/homepage/hero/HomepageHero";
import HomepageAccolades from "src/components/homepage/HomepageAccolades";
import HomepageBuild from "src/components/homepage/HomepageBuild";
import HomepageCollect from "src/components/homepage/HomepageCollect";
import HomepageConnect from "src/components/homepage/HomepageConnect";
import HomepageFaq from "src/components/homepage/HomepageFaq";
import HomepageOverview from "src/components/homepage/HomepageOverview";
import HomepagePlay from "src/components/homepage/HomepagePlay";
import HomepageSocial from "src/components/homepage/HomepageSocial";
import SEO from "src/components/SEO";

export const IndexPage = () => {
  return (
    <>
      <SEO />

      <Typography
        variant="h1"
        style={{ visibility: "hidden", position: "absolute", top: "-100vh" }}
      >
        Homepage
      </Typography>

      <HomepageHero />
      <HomepageAccolades />

      <HomepageOverview />
      <HomepagePlay />
      <HomepageCollect />
      <HomepageBuild />
      <HomepageConnect />
      <HomepageSocial />
      <HomepageFaq />
    </>
  );
};

export default IndexPage;
