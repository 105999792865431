import Link from "next/link";
import React from "react";
import {
  Container,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Routes from "../../routes";
import BackgroundImage from "../BackgroundImage";
import Image from "../Image";

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    minHeight: "95vh",
    paddingBottom: "12vh",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8vh",
    },
  },
  sectionHeader: {
    fontFamily: "Upgrade Black",
    fontSize: "100px",
    textShadow: "0px 3px 6px rgba(0,0,0,0.4)",
    margin: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "40px",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
}));

const HomepagePlay = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BackgroundImage
      src={
        smScreen
          ? "/images/banner_play_peanut_mobile.png"
          : "/images/banner_play_peanut.png"
      }
    >
      <div className={classes.sectionContent}>
        <Hidden only={["xs", "sm"]}>
          <Image
            src="/images/billy_one.png"
            width={759}
            height={438}
            aria-hidden={true}
            style={{
              position: "relative",
              marginBottom: "5vh",
              marginLeft: "10vw",
            }}
          />
        </Hidden>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" component="h2">
                Play your way
              </Typography>
              <Typography className={classes.joinSubtitle}>
                Jump into an immersive social party game where you play with and
                against friends in racing, vibe collection, shooting, and brawl
                game modes.
              </Typography>
              <Link href={Routes.Download} passHref>
                <Button
                  variant="contained"
                  color={smScreen ? "primary" : "secondary"}
                  size="large"
                  style={{ marginTop: "32px" }}
                >
                  Play Now
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </BackgroundImage>
  );
};

export default HomepagePlay;
